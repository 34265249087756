import { Button } from "@mui/material";
import React from "react";
import DeloitteLogo from "./Deloitte_logo_ui_react.png"

function Header() {

  return (
    <div className="flex justify-between m-4">
      <div className="flex-shrink-0">
        <img
          className="image"
          src={DeloitteLogo}
          alt="Deloitte Logo"
          style={{height:"32px"}}
        ></img>
      </div>
      <div className="self-center mx-4" style={{marginTop:'0rem',color:'white',marginLeft:'-6rem'}}>
        {/* <h1 className="font-bold text-3xl">Underwriting Notes Analysis</h1> */}
        <h1 className="font-bold text-3xl">Metadata RAG Implementation</h1>

      </div>
      <div className="self-start">
        {/* <Button variant="outlined" onClick={signOut}>
          Sign out
        </Button> */}
      </div>
    </div>
  );
}
export default Header;
