//@ts-nocheck
// App.tsx
import React from 'react';
import PdfViewer from './PdfViewer';
import './App.css';
import StepperComp from './StepperComp';
import HierarchyDropdownd from './HierarchyDropdownd';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Header from './Header';
import MainPage from './MainPage';
import MainPageEmail from './MainPageEmail';

import PRUPAGE from './PRUPAGE';
function App() {
  return (
    <Router>
    <Routes>
      <Route path="/" element={<MainPageEmail />} />
      {/* <Route path="/" element={<PRUPAGE />} /> */}

      {/* <Route path="/upload-documents" element={<MainPage />} /> */}
      {/* <Route path="/emailtrigger" element={<MainPageEmail />} /> */}

    </Routes>
  </Router>

    // <div className="App">

    //   {/* <Del/>  */}
    //   {/* <PdfViewer /> */}
    //   <Header />
    //   <InitialPage />
    //   {/* <div className="flex flex-row">
    //     <MainPage></MainPage>

    //   </div> */}
    //   {/* <StepperComp /> */}
    //   {/* <HierarchyDropdownd></HierarchyDropdownd> */}
    //   {/* <MultiSelect></MultiSelect> */}
    // </div>
  );
}

export default App;
